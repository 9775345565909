<template>
  <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
    <el-tiptap v-model="content" :extensions="extensions"></el-tiptap>
    <wang-editor
      :config="{ placeholder: '请输入正文（最长 1 万字）'}"
      @change="wangEditorChange"
      :value="content"
    >
    </wang-editor>
  </a-card>
</template>

<script>
import {
  // 需要的 extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
  History,
  Preview,
  TextColor,
  FormatClear,
  Link,
  Iframe,
  CodeBlock,
  Blockquote,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  LineHeight,
  FontType,
  FontSize
} from 'element-tiptap'
import NoticeIcon from '@/components/NoticeIcon/NoticeIcon'
import WangEditor from '@/components/Editor/WangEditor'
export default {
  name: 'RichText',
  components: {
    NoticeIcon,
    WangEditor
  },
  data () {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
        new Underline({ bubble: true, menubar: false }), // 在气泡菜单而不在菜单栏中渲染菜单按钮
        new TextColor(),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Image({
          // 自定义图片上传(默认会把图片生成base64字符串和内容存储到一起)
          // 返回一个 Promise<url>
          uploadRequest (file) {
            return new Promise((resolve) => {
              resolve('//img.dadaojianqi.com/image/2022/0814/jkpwfixr1z227a.jpeg')
            })
          }
        }),
        new History(),
        new Preview(),
        new FormatClear(),
        new Link(),
        new Iframe(),
        new CodeBlock(),
        new Blockquote(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new Indent(),
        new LineHeight(),
        new FontType(),
        new FontSize()
      ],
      content: `
        <h1>Heading</h1>
        <p>This Editor is awesome!</p>
      `
    }
  },
  methods: {
    uploadRequest (file) {
      console.log('uploadRequest', file)
    },
    wangEditorChange (e) {
      this.content = e
      console.log('wangEditorChange', this.content)
    }
  }
}
</script>
